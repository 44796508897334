import { Box, BoxProps, Container } from "@mui/material"
import { FC, ReactNode } from "react"
import CategorySectionHeader from "./CategorySectionHeader"

// ==============================================================
type CategorySectionCreatorProps = {
  title?: string
  icon?: ReactNode
  seeMoreLink?: string
}
// ==============================================================

const CategorySectionCreator: FC<CategorySectionCreatorProps & BoxProps> = (
  props
) => {
  const { icon, title, children, seeMoreLink, ...others } = props

  return (
    <Box sx={{ mb: { xs: 2, md: 4 } }} {...others}>
      <Container>
        {title && (
          <CategorySectionHeader
            title={title}
            seeMoreLink={seeMoreLink}
            icon={icon}
          />
        )}

        {children}
      </Container>
    </Box>
  )
}

export default CategorySectionCreator
